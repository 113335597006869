var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { id: "order-store_id", required: false },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("e-search-sku", {
                    attrs: {
                      id: "skuId",
                      required: false,
                      "default-options": _vm.defaultOptions.skus,
                    },
                    on: { input: _vm.onSelectSku },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "brand",
                      type: "vue-select",
                      label: _vm.$t("Marca"),
                      placeholder: _vm.$t("Selecione uma marca"),
                      options: _vm.getComboBrands,
                    },
                    model: {
                      value: _vm.filters.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "brand", $$v)
                      },
                      expression: "filters.brand",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "group",
                      type: "vue-select",
                      label: _vm.$t("Grupo"),
                      placeholder: _vm.$t("Selecione um grupo"),
                      options: _vm.getComboGroups,
                    },
                    on: { input: _vm.onSelectGroup },
                    model: {
                      value: _vm.filters.group,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "group", $$v)
                      },
                      expression: "filters.group",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "subgroup",
                      type: "vue-select",
                      label: _vm.$t("Subgrupo"),
                      placeholder: _vm.$t("Selecione um subgrupo"),
                      options: _vm.getComboSubgroups,
                      disabled: !_vm.filters.group,
                    },
                    model: {
                      value: _vm.filters.subgroup,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "subgroup", $$v)
                      },
                      expression: "filters.subgroup",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "department",
                      type: "vue-select",
                      label: _vm.$t("Departamento"),
                      placeholder: _vm.$t("Selecione um departamento"),
                      options: _vm.getComboDepartments,
                    },
                    model: {
                      value: _vm.filters.department,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "department", $$v)
                      },
                      expression: "filters.department",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "line",
                      type: "vue-select",
                      label: _vm.$t("Linha"),
                      placeholder: _vm.$t("Selecione uma linha"),
                      options: _vm.getComboLines,
                    },
                    model: {
                      value: _vm.filters.line,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "line", $$v)
                      },
                      expression: "filters.line",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              id: "product_average_cost-table",
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.$t("PRODUCT_AVERAGE_COST.UI.EMPTY_TABLE"),
              fields: _vm.fields,
              items: _vm.productsAverageCost,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        buttons: [
                          {
                            event: "show-cost-changes",
                            icon: "clock",
                            title: "Alterações do custo médio",
                          },
                        ],
                      },
                      on: {
                        "show-cost-changes": function ($event) {
                          return _vm.onShowAverageCostChangeModal(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(averageCost)",
                fn: function (row) {
                  return [
                    _c("FormulateInput", {
                      staticClass: "text-right",
                      attrs: {
                        id: "product_average_cost-" + row.index,
                        type: _vm.readOnly ? "label" : "text-number",
                        precision: 3,
                        currency: "R$",
                        filter: "currency",
                        disabled: row.item.busy,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.onUpdateAverageCost(row.item)
                        },
                      },
                      model: {
                        value: row.item.averageCost,
                        callback: function ($$v) {
                          _vm.$set(row.item, "averageCost", $$v)
                        },
                        expression: "row.item.averageCost",
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(name)",
                fn: function (row) {
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": row.item.skuId,
                        "product-id": row.item.productId,
                        "product-name": row.item.name,
                        ean: row.item.ean,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "product_average_cost",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("UpdateAveragePrice", "Product")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Incluir custo médio") },
            on: { click: _vm.onShowAverageCostSidebar },
          })
        : _vm._e(),
      _c("product-average-cost-sidebar", {
        ref: "productAverageCostSidebar",
        on: { "after-save": _vm.getData },
      }),
      _vm.$can("ReadAveragePriceChanges", "Product")
        ? _c("product-average-cost-changes-modal", {
            ref: "productAverageCostChangesModal",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }