<template>
  <b-modal
    id="average_cost_change_modal"
    no-close-on-backdrop
    hide-footer
    size="lg"
    scrollable
    centered
    @shown="onShownModal"
    @hidden="onResetData"
  >
    <template #modal-title>
      <p class="h4">
        {{ $t('Alterações do custo médio') }}
      </p>
    </template>

    <b-card-actions
      no-actions
      :show-loading="loadingModal"
      :title="$t('Detalhes')"
    >
      <b-row>
        <b-col md="3">
          <FormulateInput
            id="changes_modal-store"
            v-model="localData.store"
            type="label"
            :label="$t('Loja')"
            filter="storeName"
          />
        </b-col>
        <b-col>
          <p class="h6 m-0">
            Produto
          </p>
          <e-product-router-link
            class="custom-product"
            :sku-id="localData.sku.id"
            :product-id="localData.sku.productId"
            :product-name="localData.sku.name"
            :ean="localData.sku.ean"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card-actions
      no-actions
      :show-loading="loadingModal || fetching"
    >
      <b-row class="pt-1">
        <b-col>
          <div class="mb-2">
            <e-page-size-selector
              :per-page="paging.pageSize"
              @change="pageSizeChange"
            />
          </div>

          <b-table
            ref="department-table"
            show-empty
            responsive
            striped
            :items="averageCostChanges"
            :fields="fields"
            class="bordered"
            :empty-text="getEmptyTableMessage($tc('PRODUCT_AVERAGE_COST_CHANGES.NAME'))"
            no-local-sorting
            :sort-by.sync="sorting.sortBy"
            :sort-desc.sync="sorting.sortDesc"
            @sort-changed="reloadTable"
          >
            <template #cell(name)="{ item }">
              <e-product-router-link
                :sku-id="item.sku.id"
                :product-id="item.sku.productId"
                :product-name="item.sku.name"
                :ean="item.sku.ean"
              />
            </template>

            <template #cell(changeOrigin)="row">
              <span> {{ row.item.changeOriginLabel.label }}</span>

              <e-router-link
                v-if="row.item.changeOriginLabel.routerName"
                :to="{
                  name: row.item.changeOriginLabel.routerName,
                  params: row.item.changeOriginLabel.routerParams,
                  query: row.item.changeOriginLabel.query,
                }"
                :value="row.item.changeOriginLabel.value"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="clientHistory-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </b-modal>
</template>

<script>
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { BModal, BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import { sorting, statusTypes, storePagination } from '@/mixins'
import EProductRouterLink from '@/views/components/EProductRouterLink.vue'
import ERouterLink from '@/views/components/ERouterLink.vue'

const getInitialLocalData = () => ({
  sku: {},
  store: {},
})

export default {
  components: {
    BModal,
    BCardActions,
    BCol,
    BRow,
    BTable,
    BPagination,
    EPageSizeSelector,
    EPaginationSummary,
    EProductRouterLink,
    ERouterLink,
  },

  mixins: [statusTypes],

  props: {},

  data() {
    return {
      averageCostChanges: [],
      localData: getInitialLocalData(),
      loadingModal: false,
      fetching: false,
      ...storePagination.state(),
    }
  },

  computed: {
    fields() {
      return [
        {
          label: this.$t('Data/Hora'),
          key: 'createdDate',
          class: 'text-center',
          thStyle: { width: '140px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        // {
        //   label: this.$t('Loja'),
        //   key: 'store',
        //   thClass: 'text-center',
        //   tdClass: 'text-left',
        //   thStyle: { width: '300px' },
        //   formatter: val => this.$options.filters.storeName(val),
        // },
        // {
        //   label: this.$t('Produto'),
        //   key: 'name',
        //   thClass: 'text-center',
        //   tdClass: 'text-left',
        //   sortable: false,
        // },
        {
          label: this.$t('Responsável'),
          key: 'createdBy',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: false,
        },
        {
          label: this.$t('Custo médio'),
          key: 'averageCost',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Origem'),
          key: 'changeOrigin',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '200px' },
        },
      ]
    },
  },

  methods: {
    async getData() {
      const { sku, store } = this.localData
      const { data } = await this.$http.get('/api/skus/average-costs/changes', {
        params: {
          skuId: sku?.id,
          storeId: store?.id,
          pageIndex: this.paging.currentPage - 1,
          pageSize: this.paging.pageSize,
          sortBy: sorting.methods.getSorting({ sorting: this.sorting }),
        },
      })

      this.paging = {
        ...this.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      }

      this.averageCostChanges = (data?.results || []).map(avgChange => ({
        ...avgChange,
        changeOriginLabel: this.getChangeOriginLabel(avgChange),
      }))
    },

    show(data) {
      this.onResetData()

      this.localData = {
        ...getInitialLocalData(),
        ...data,
      }
      this.$bvModal.show('average_cost_change_modal')
    },
    // este método executa após o "show" do modal (o show possui delay)
    async onShownModal() {
      this.$nextTick(async () => {
        this.loadingModal = true
        try {
          await this.getData()
        } catch (error) {
          this.showError({ error })
          this.onHideModal()
        } finally {
          this.loadingModal = false
        }
      })
    },
    async reloadTable() {
      this.$nextTick(async () => {
        this.fetching = true
        try {
          await this.getData()
        } catch (error) {
          this.showError({ error })
          throw error
        } finally {
          this.fetching = false
        }
      })
    },

    onHideModal() {
      this.onResetData()
      this.$bvModal.hide('average_cost_change_modal')
    },

    getChangeOriginLabel(item) {
      const config = () => {
        if (item.purchaseOrderId) {
          return {
            label: this.$t('Pedido de compra'),
            value: item.purchaseOrderId,
            routerName: 'purchase-order-details',
            routerParams: { id: item.purchaseOrderId },
          }
        }

        if (item.skuStockMovementId) {
          return {
            label: this.$t('Movimentação de estoque'),
            value: item.skuStockMovementId,
            routerName: 'stock-movement-list',
            query: { storeId: item.store.id, skuId: item.sku.id },
          }
        }

        return {}
      }

      return {
        label: config().label || this.recordTypeLabel[item.recordType],
        value: config().value,
        routerName: config().routerName,
        routerParams: config().routerParams,
        query: config().query,
      }
    },

    async onResetData() {
      this.averageCostChanges = []
      this.localData = getInitialLocalData()
      this.paging = storePagination.state().paging
    },

    async pageChange(currentPage) {
      this.paging.currentPage = currentPage
      await this.reloadTable()
    },
    async pageSizeChange(pageSize) {
      this.paging.currentPage = 1
      this.paging.pageSize = pageSize
      await this.reloadTable()
    },
  },
}
</script>

<style lang="scss" scoped></style>
