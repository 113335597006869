<template>
  <FormulateForm
    ref="averageSidebarForm"
    name="averageSidebarForm"
  >
    <e-sidebar
      id="sidebar-average-cost"
      :title="$t('Incluir custo médio')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveAverageCost"
      @hidden="showSidebar = false"
    >
      <template #content>
        <b-row>
          <b-col md="12">
            <e-store-combo
              id="average-store"
              v-model="averageForm.storeId"
            />
          </b-col>
          <b-col md="12">
            <e-search-sku
              id="average-product"
              v-model="averageForm.skuId"
              :label="$t('Produto')"
              :only-active="true"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="average-price"
              v-model.number="averageForm.averageCost"
              type="text-number"
              currency="R$"
              :precision="2"
              class="required"
              :label="$t('Custo médio')"
              validation="required|min:0.01"
            />
          </b-col>
        </b-row>
      </template>
    </e-sidebar>
  </FormulateForm>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar, ESearchSku } from '@/views/components'
import { formulateHelper } from '@/mixins'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import { mapActions } from 'vuex'

const getInitialForm = () => ({
  storeId: null,
  skuId: null,
  averageCost: 0,
})

export default {
  components: { BRow, BCol, ESidebar, ESearchSku, EStoreCombo },

  mixins: [formulateHelper],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      averageForm: getInitialForm(),
    }
  },

  computed: {},

  methods: {
    ...mapActions('pages/catalog/productAverageCost', ['updateProductAverageCost']),

    show() {
      this.averageForm = getInitialForm()
      this.$refs.averageSidebarForm.hideErrors()

      this.showSidebar = true
    },

    async saveAverageCost() {
      this.$refs.averageSidebarForm.showErrors()
      if (this.$refs.averageSidebarForm.isValid) {
        try {
          this.saving = true

          await this.updateProductAverageCost({
            skuId: this.averageForm.skuId,
            storeId: this.averageForm.storeId,
            averageCost: this.averageForm.averageCost,
          })

          this.showSuccess({ message: this.$t('Incluído com sucesso') })
          this.showSidebar = false
          this.$emit('after-save')
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
        } finally {
          this.saving = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
