<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="order-store_id"
            v-model="filters.storeId"
            :required="false"
          />
        </b-col>
        <b-col md="5">
          <e-search-sku
            id="skuId"
            v-model="filters.skuId"
            :required="false"
            :default-options="defaultOptions.skus"
            @input="onSelectSku"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="brand"
            v-model="filters.brand"
            type="vue-select"
            :label="$t('Marca')"
            :placeholder="$t('Selecione uma marca')"
            :options="getComboBrands"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="group"
            v-model="filters.group"
            type="vue-select"
            :label="$t('Grupo')"
            :placeholder="$t('Selecione um grupo')"
            :options="getComboGroups"
            @input="onSelectGroup"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="subgroup"
            v-model="filters.subgroup"
            type="vue-select"
            :label="$t('Subgrupo')"
            :placeholder="$t('Selecione um subgrupo')"
            :options="getComboSubgroups"
            :disabled="!filters.group"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="department"
            v-model="filters.department"
            type="vue-select"
            :label="$t('Departamento')"
            :placeholder="$t('Selecione um departamento')"
            :options="getComboDepartments"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="line"
            v-model="filters.line"
            type="vue-select"
            :label="$t('Linha')"
            :placeholder="$t('Selecione uma linha')"
            :options="getComboLines"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="product_average_cost-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="$t('PRODUCT_AVERAGE_COST.UI.EMPTY_TABLE')"
        :fields="fields"
        :items="productsAverageCost"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="[
              {
                event: 'show-cost-changes',
                icon: 'clock',
                title: 'Alterações do custo médio',
              },
            ]"
            @show-cost-changes="onShowAverageCostChangeModal(row.item)"
          />
        </template>

        <template #cell(averageCost)="row">
          <FormulateInput
            :id="`product_average_cost-${row.index}`"
            v-model="row.item.averageCost"
            :type="readOnly ? 'label' : 'text-number'"
            :precision="3"
            currency="R$"
            class="text-right"
            filter="currency"
            :disabled="row.item.busy"
            @blur="onUpdateAverageCost(row.item)"
          />
        </template>

        <template #cell(name)="row">
          <e-product-router-link
            :sku-id="row.item.skuId"
            :product-id="row.item.productId"
            :product-name="row.item.name"
            :ean="row.item.ean"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product_average_cost"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('UpdateAveragePrice', 'Product')"
      :main-tooltip="$t('Incluir custo médio')"
      @click="onShowAverageCostSidebar"
    />

    <product-average-cost-sidebar
      ref="productAverageCostSidebar"
      @after-save="getData"
    />

    <product-average-cost-changes-modal
      v-if="$can('ReadAveragePriceChanges', 'Product')"
      ref="productAverageCostChangesModal"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters } from '@/views/components'
import { statusTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import EProductRouterLink from '@/views/components/EProductRouterLink.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import fab from '@/views/components/FAB.vue'
import ProductAverageCostChangesModal from './components/ProductAverageCostChangesModal.vue'
import ProductAverageCostSidebar from './components/ProductAverageCostSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EFilters,
    EStoreCombo,
    ESearchSku,
    EProductRouterLink,
    ProductAverageCostChangesModal,
    EGridActions,
    ProductAverageCostSidebar,
    fab,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
      saving: false,
      waitUpdateConfirm: false,
      product: {
        id: null,
        name: '',
      },
    }
  },

  computed: {
    ...mapState('pages/catalog/productAverageCost', [
      'filters',
      'defaultOptions',
      'sorting',
      'productsAverageCost',
      'paging',
    ]),
    ...mapGetters('pages/catalog/products', [
      'getComboDepartments',
      'getComboLines',
      'getComboUnits',
      'getComboBrands',
      'getComboGroups',
      'getComboSubgroups',
    ]),

    readOnly() {
      return !this.$can('UpdateAveragePrice', 'Product')
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: !this.$can('ReadAveragePriceChanges', 'Product'),
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '300px' },
          formatter: val => this.$options.filters.storeName(val),
        },
        {
          label: this.$t('Id'),
          key: 'skuId',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: false,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: false,
        },
        {
          label: this.$t('Custo médio'),
          key: 'averageCost',
          class: 'text-center',
          thStyle: { width: '150px' },
        },
      ].filter(f => !f.hide)
    },
  },

  async mounted() {
    this.getData()
    await this.fetchProductCombos()
  },

  methods: {
    ...mapActions('pages/catalog/products', ['setComboSubgroups', 'fetchProductCombos']),
    ...mapActions('pages/catalog/productAverageCost', [
      'fetchProductAverageCosts',
      'updateProductAverageCost',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
      'setDefaultOptions',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchProductAverageCosts()
          this.searched = true
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onSelectGroup(idValue) {
      this.filters.subgroup = null
      this.setComboSubgroups(idValue)
    },
    onSelectSku(val, options) {
      this.setDefaultOptions({ property: 'skus', optionList: options })
    },

    onShowAverageCostSidebar() {
      this.$refs.productAverageCostSidebar.show()
    },
    onShowAverageCostChangeModal(item) {
      this.$refs.productAverageCostChangesModal.show({
        sku: { id: item.skuId, ...item },
        store: item.store,
      })
    },

    /* eslint-disable no-param-reassign */
    async onUpdateAverageCost(item) {
      if (this.waitUpdateConfirm) return
      this.waitUpdateConfirm = true

      const { skuId, store, name, averageCost, originalValue } = item || {}

      try {
        item.busy = true
        const confirm = await this.confirm({
          title: this.$t('Confirma alteração?'),
          text: this.$t('PRODUCT_AVERAGE_COST.UI.UPDATE_CONFIRMATION_TEXT', {
            productName: name,
            newCurrencyValue: this.$options.filters.currency(averageCost, 0, 3),
          }),
        })

        this.waitUpdateConfirm = false

        if (!confirm) {
          item.averageCost = originalValue.averageCost
          return
        }

        const data = await this.updateProductAverageCost({ storeId: store?.id, skuId, averageCost })
        item.originalValue = data

        this.showSuccess({ icon: 'EditIcon', title: this.$t('Atualizado com sucesso') })
      } catch (error) {
        item.averageCost = originalValue.averageCost
        this.showError({ error })
      } finally {
        item.busy = false
      }
    },
    /* eslint-enable no-param-reassign */
  },
}
</script>

<style></style>
