var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "averageSidebarForm", attrs: { name: "averageSidebarForm" } },
    [
      _c("e-sidebar", {
        attrs: {
          id: "sidebar-average-cost",
          title: _vm.$t("Incluir custo médio"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: {
          save: _vm.saveAverageCost,
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          attrs: { id: "average-store" },
                          model: {
                            value: _vm.averageForm.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.averageForm, "storeId", $$v)
                            },
                            expression: "averageForm.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-search-sku", {
                          attrs: {
                            id: "average-product",
                            label: _vm.$t("Produto"),
                            "only-active": true,
                          },
                          model: {
                            value: _vm.averageForm.skuId,
                            callback: function ($$v) {
                              _vm.$set(_vm.averageForm, "skuId", $$v)
                            },
                            expression: "averageForm.skuId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "average-price",
                            type: "text-number",
                            currency: "R$",
                            precision: 2,
                            label: _vm.$t("Custo médio"),
                            validation: "required|min:0.01",
                          },
                          model: {
                            value: _vm.averageForm.averageCost,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.averageForm,
                                "averageCost",
                                _vm._n($$v)
                              )
                            },
                            expression: "averageForm.averageCost",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }