var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "average_cost_change_modal",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "lg",
        scrollable: "",
        centered: "",
      },
      on: { shown: _vm.onShownModal, hidden: _vm.onResetData },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.$t("Alterações do custo médio")) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "no-actions": "",
            "show-loading": _vm.loadingModal,
            title: _vm.$t("Detalhes"),
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "changes_modal-store",
                      type: "label",
                      label: _vm.$t("Loja"),
                      filter: "storeName",
                    },
                    model: {
                      value: _vm.localData.store,
                      callback: function ($$v) {
                        _vm.$set(_vm.localData, "store", $$v)
                      },
                      expression: "localData.store",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("p", { staticClass: "h6 m-0" }, [_vm._v(" Produto ")]),
                  _c("e-product-router-link", {
                    staticClass: "custom-product",
                    attrs: {
                      "sku-id": _vm.localData.sku.id,
                      "product-id": _vm.localData.sku.productId,
                      "product-name": _vm.localData.sku.name,
                      ean: _vm.localData.sku.ean,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: {
            "no-actions": "",
            "show-loading": _vm.loadingModal || _vm.fetching,
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("e-page-size-selector", {
                        attrs: { "per-page": _vm.paging.pageSize },
                        on: { change: _vm.pageSizeChange },
                      }),
                    ],
                    1
                  ),
                  _c("b-table", {
                    ref: "department-table",
                    staticClass: "bordered",
                    attrs: {
                      "show-empty": "",
                      responsive: "",
                      striped: "",
                      items: _vm.averageCostChanges,
                      fields: _vm.fields,
                      "empty-text": _vm.getEmptyTableMessage(
                        _vm.$tc("PRODUCT_AVERAGE_COST_CHANGES.NAME")
                      ),
                      "no-local-sorting": "",
                      "sort-by": _vm.sorting.sortBy,
                      "sort-desc": _vm.sorting.sortDesc,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        return _vm.$set(_vm.sorting, "sortBy", $event)
                      },
                      "update:sort-by": function ($event) {
                        return _vm.$set(_vm.sorting, "sortBy", $event)
                      },
                      "update:sortDesc": function ($event) {
                        return _vm.$set(_vm.sorting, "sortDesc", $event)
                      },
                      "update:sort-desc": function ($event) {
                        return _vm.$set(_vm.sorting, "sortDesc", $event)
                      },
                      "sort-changed": _vm.reloadTable,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(name)",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c("e-product-router-link", {
                              attrs: {
                                "sku-id": item.sku.id,
                                "product-id": item.sku.productId,
                                "product-name": item.sku.name,
                                ean: item.sku.ean,
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(changeOrigin)",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(row.item.changeOriginLabel.label)
                              ),
                            ]),
                            row.item.changeOriginLabel.routerName
                              ? _c("e-router-link", {
                                  attrs: {
                                    to: {
                                      name: row.item.changeOriginLabel
                                        .routerName,
                                      params:
                                        row.item.changeOriginLabel.routerParams,
                                      query: row.item.changeOriginLabel.query,
                                    },
                                    value: row.item.changeOriginLabel.value,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "clientHistory-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }